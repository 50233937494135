
import {defineComponent, ref} from 'vue';
import {Toast} from 'bootstrap';

/**
 * Shows a Bootstrap toast component.
 * Will show an icon, title and button to dismiss the toast.
 * By default it will show the passed body as the content but this can be
 * overridden by using the default slot.
 */
export default defineComponent({
    props: {
        autoShow: {
            type: Boolean,
            default: true,
        },
        body: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: 'info',
        },
        title: {
            type: String,
            default: '',
        },
    },
    emits: [
        'hidden',
    ],
    setup() {
        return {
            toast: ref<Toast>(),
        };
    },
    mounted() {
        this.assignToastInstance();

        this.$el.addEventListener('hidden.bs.toast', this.emitHidden);

        if (this.autoShow && this.toast) {
            this.toast.show();
        }
    },
    beforeUnmount() {
        this.$el.removeEventListener('hidden.bs.toast', this.emitHidden);
    },
    methods: {
        assignToastInstance() {
            const toastElement = this.$el;

            if (!toastElement) {
                return;
            }

            this.toast = new Toast(toastElement);
        },
        emitHidden() {
            this.$emit('hidden');
        },
    },
});

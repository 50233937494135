import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_ctx.showNotifications)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
          return (_openBlock(), _createBlock(_component_Toast, {
            id: `toast-${notification.id}`,
            key: notification.id,
            class: "shadow-sm",
            title: _ctx.$t(`notifications.titles.${notification.type}`),
            onHidden: ($event: any) => (_ctx.removeNotification(notification.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('notifications.body', [notification.initiator.fullName])) + " ", 1),
              _createVNode(_component_RouterLink, {
                class: "text-decoration-none",
                to: notification.getModelLocation()
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(notification.subject), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onHidden"]))
        }), 128)),
        (!_ctx.inFocusMode && _ctx.missedNotificationsCount)
          ? (_openBlock(), _createBlock(_component_Toast, {
              key: 0,
              body: _ctx.$t('notifications.missed.body'),
              title: _ctx.$t('notifications.missed.title', [_ctx.missedNotificationsCount]),
              onHidden: _cache[0] || (_cache[0] = ($event: any) => (_ctx.missedNotificationsCount = 0))
            }, null, 8, ["body", "title"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
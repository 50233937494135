import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a619bf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "mb-6" }
const _hoisted_4 = { class: "card-footer text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `step-${index}`,
          class: "d-flex align-items-center mt-4"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["step-indicator rounded-circle d-flex justify-content-center align-items-center", [step.completed ? 'bg-primary text-white' : 'border']])
          }, [
            (step.completed)
              ? (_openBlock(), _createBlock(_component_Fa, {
                  key: 0,
                  icon: "check",
                  size: "xs"
                }))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("span", {
            class: _normalizeClass(["ms-6", [step.completed ? 'text-body' : step.active ? 'text-primary' : 'text-muted']])
          }, _toDisplayString(step.title), 3)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "btn btn-white",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('skipped')))
      }, _toDisplayString(_ctx.$t('onboarding.actions.skipForNow')), 1),
      _createElementVNode("button", {
        class: "btn btn-primary ms-2",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onContinue && _ctx.onContinue(...args)))
      }, _toDisplayString(_ctx.$t(`onboarding.actions.${_ctx.lastStepActive ? 'letsGo' : 'continue'}`)), 1)
    ])
  ]))
}

import {PropType, defineComponent} from 'vue';

export interface Step {
    active?: boolean;
    completed?: boolean;
    title: string;
}

export default defineComponent({
    props: {
        steps: {
            type: Array as PropType<Step[]>,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
    },
    emits: [
        'continue',
        'finished',
        'skipped',
    ],
    computed: {
        lastStepActive(): boolean {
            return this.steps.length
                ? this.steps[this.steps.length - 1].active || false
                : false;
        },
    },
    methods: {
        onContinue() {
            this.$emit(this.lastStepActive ? 'finished' : 'continue');
        },
    },
});

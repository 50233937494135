
import StepGuide, {Step} from '@/components/introduction/StepGuide.vue';
import onboardingStore, {OnboardingStep} from '@/store/onboarding';
import Toasts from '@/components/notifications/Toasts.vue';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        StepGuide,
        Toasts,
    },
    setup() {
        const {
            activeStep,
            onboardingSteps,
            completeCurrentStep,
            showOnboarding,
        } = onboardingStore;

        return {
            activeStep,
            onboardingSteps,
            completeCurrentStep,
            showOnboarding,
        };
    },
    computed: {
        steps(): Step[] {
            return this.onboardingSteps.map((step: OnboardingStep) => ({
                active: this.activeStep?.completionKey === step.completionKey,
                completed: step.completed,
                title: this.$t(step.titleKey),
            }));
        },
    },
    watch: {
        showOnboarding(value: boolean) {
            if (!value) {
                this.hideOnboardingSteps();
            }
        },
    },
    methods: {
        hideOnboardingSteps() {
            onboardingStore.resetState();
        },
    },
});


import Notification, {Notifications} from '@/models/Notification';
import {defineComponent, ref} from 'vue';
import Toast from '@/components/common/Toast.vue';
import authStore from '@/store/auth';
import editStore from '@/store/pitches/edit';
import horn from '@/assets/audio/horn.mp3';

export default defineComponent({
    components: {
        Toast,
    },
    setup() {
        return {
            inFocusMode: editStore.inFocusMode,
            notifications: ref(new Notifications()),
            missedNotificationsCount: ref<number>(0),
            user: authStore.me,
        };
    },
    computed: {
        showNotifications(): boolean {
            /*
             * The notifications should be shown if there are any, or if the
             * user is not in focus mode and has missed notifications.
             * Any notifications that are received while in focusmode are not added
             * to the notifications list, but increment the missed counter by one.
             */
            return this.notifications.count() > 0
                || (!this.inFocusMode && this.missedNotificationsCount > 0);
        },
    },
    created() {
        if (!this.user) {
            return;
        }

        this.user.websocket.listen('Notifications\\NotificationCreated', (data: Record<string, any>) => {
            this.addNotification(new Notification(data));
        });
    },
    methods: {
        addNotification(notification: Notification) {
            if (this.inFocusMode) {
                this.missedNotificationsCount++;

                return;
            }

            this.notifications.push(notification);

            // 0.1% chance to play the horn sound
            if (Math.floor(Math.random() * 1000) !== 0) {
                return;
            }

            const hornAudio = new Audio(horn);

            hornAudio.volume = 0.25;

            hornAudio.play();
        },
        removeNotification(id: string) {
            this.notifications.removeFirstWhere((n: Notification) => n.id === id);
        },
    },
});

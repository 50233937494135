import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Toasts = _resolveComponent("Toasts")!
  const _component_StepGuide = _resolveComponent("StepGuide")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView),
    _createVNode(_component_Toasts, { class: "toasts space-y-2 position-fixed bottom-0 start-0 p-4" }),
    (_ctx.showOnboarding && 1 === 2)
      ? (_openBlock(), _createBlock(_component_StepGuide, {
          key: 0,
          class: "position-fixed z-index-1 bottom-4 end-4 min-w-300px shadow",
          steps: _ctx.steps,
          title: _ctx.$t('onboarding.title'),
          onContinue: _ctx.completeCurrentStep,
          onFinished: _ctx.hideOnboardingSteps,
          onSkipped: _ctx.hideOnboardingSteps
        }, null, 8, ["steps", "title", "onContinue", "onFinished", "onSkipped"]))
      : _createCommentVNode("", true)
  ], 64))
}